import { toEstimate, shippingDateSelectionReplace } from "../Shared/SiteCommon";
//import { cookie } from "../Shared/Cookie";

namespace ComicSamuraiSiteCommon {
    const siteId = 11;
    jQuery(function () {

        if (location.href.indexOf('/contact') != -1) {
            location.href = 'https://www.comic-samurai.com/page/11';
        }
        var alist = jQuery("a[href^='https://www.comic-samurai.com/contact']");
        alist.attr("href", '/page/11?from=top');

        setupEstimateButton();
        shippingDateSelectionReplace();
    });

    function setupEstimateButton() {
        //カートの最初のページのみ発動
        if (document.getElementById("shoppingcart_page_step0") != null) {
            const form = jQuery("#register");
            const formBtns = form.find(".form_btn");
            const lastElem = formBtns.last();
            lastElem.append(
                `<span class="global_btn registerinput_btn">
    <button type="button" id="estimate" class="registerinput btn_size_xxlarge btn_color_emphasis">
        <span class="button_head_space"></span>
        <span class="button_text">見積書発行</span>
        <span class="button_end_space"></span>
    </button>
</span>`);
            const estBtn = document.getElementById("estimate");
            estBtn.onclick = <any>((a, b) => {
                toEstimate(siteId);
            });
        }
    }
} 